const styles = theme => ({
  root: {
    flexGrow: 1,
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  disclaimer: {
    padding: '24px',
    background: `linear-gradient(180deg, rgba(127,23,23,1) 0%, rgba(10,21,21,1) 100%)`,
    borderRadius: '12px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginBottom: '2rem',
    fontWeight: 900,
    color: theme.palette.text.primary,
  },
  title: {
    padding: '24px',
    background: `linear-gradient(180deg, rgba(127,23,23,1) 0%, rgba(10,21,21,1) 100%)`,
    borderRadius: '12px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    marginBottom: '2rem',
    color: theme.palette.text.primary,
    fontFamily:"FangGang",
    fontSize: "32px",
    fontWeight: "440",
    lineHeight: "48px"
  }
});

export default styles;
