export const fantomPools = [
  {
    id: 'fang-fang',
    logo: 'single-assets/FANG.png',
    name: 'FANG',
    token: 'FANG',
    tokenDescription: 'PotluckProtocol.com',
    tokenAddress: '0x49894fCC07233957c35462cfC3418Ef0CC26129f',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'sFANG',
    earnedTokenAddress: '0xbF265e41Bef9438a68376C342dfc02601b7eab07',
    earnContractAddress: '0xbF265e41Bef9438a68376C342dfc02601b7eab07',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'tokens',
    oracleId: 'fang-token',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    assets: ['fang-token'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_NONE',
      'MCAP_MEDIUM',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'SingleStake',
    withdrawalFee: '0%',
    buyTokenUrl:
      'https://app.potluckprotocol.com/swap?outputCurrency=0x49894fCC07233957c35462cfC3418Ef0CC26129f',
  },
  {
    id: 'potluck-fang-ftm',
    name: 'FANG-FTM LP',
    token: 'FANG-FTM LP',
    tokenDescription: 'PotluckProtocol.com(SpiritSwap)',
    tokenAddress: '0x871DD566AB3De61E5Cc8fb16fEE82595b17e9cc6',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'FCR-FANG-FTM',
    earnedTokenAddress: '0x350b5214dDC88B8A90D4b0dCDE004B711f93Fa7C',
    earnContractAddress: '0x350b5214dDC88B8A90D4b0dCDE004B711f93Fa7C',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'potluck-fang-ftm',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpiritSwap',
    withdrawalFee: '0%',
    assets: ['fang-token', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.potluckprotocol.com/add/FTM/0x49894fCC07233957c35462cfC3418Ef0CC26129f',
    buyTokenUrl:
      'https://app.potluckprotocol.com/swap?outputCurrency=0x49894fcc07233957c35462cfc3418ef0cc26129f',
  },
  {
    id: 'potluck-fang-ftm-boo',
    name: 'FANG-FTM LP(Spooky)',
    token: 'FANG-FTM LP(Spooky)',
    tokenDescription: 'PotluckProtocol.com(Spooky)',
    tokenAddress: '0x19dCe7bdE30cd966FF123d272E5f688E0b4B8B78',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'FCR-FANG-FTM-BOO',
    earnedTokenAddress: '0x42a75c039477600c53e0b72DbEf8BE41A4C9753B',
    earnContractAddress: '0x42a75c039477600c53e0b72DbEf8BE41A4C9753B',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'potluck-fang-ftm-boo',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'SpookySwap',
    withdrawalFee: '0%',
    assets: ['fang-token', 'FTM'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://app.potluckprotocol.com/add/FTM/0x49894fCC07233957c35462cfC3418Ef0CC26129f',
    buyTokenUrl:
      'https://app.potluckprotocol.com/swap?outputCurrency=0x49894fcc07233957c35462cfc3418ef0cc26129f',
  },
];
