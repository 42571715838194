const styles = theme => ({
  container: {
    marginBottom: '24px',
    border: '1px solid ' + theme.palette.background.border,
    background: `linear-gradient(180deg, rgba(127,23,23,1) 0%, rgba(10,21,21,1) 100%)`,
  },
  accordion: {
    width: '100%',
    background: `linear-gradient(180deg, rgba(127,23,23,1) 0%, rgba(10,21,21,1) 100%)`,
  },
  divider: {
    margin: '0 30px',
  },
});

export default styles;
